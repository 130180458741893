<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center">
      <v-col cols="12" sm="8" md="4">
        <v-card outlined>
          <v-card-title class="primary white--text justify-center">
            <h3>Central de solicitações</h3>
          </v-card-title>
          <v-sheet
            v-if="error"
            tile
            class="mx-auto white--text text-center"
            color="error"
          >
            {{ error.message }}
          </v-sheet>
          <v-form @submit.prevent="login()">
            <v-card-text>
              <v-text-field
                label="E-mail"
                name="login"
                prepend-icon="mdi-account"
                type="email"
                v-model="user.email"
                required
              />

              <v-text-field
                :type="showPassword ? 'text' : 'password'"
                label="Senha"
                prepend-icon="mdi-lock"
                :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showPassword = !showPassword"
                v-model="user.password"
                required
              />
            </v-card-text>
            <v-card-actions>
              <v-row>
                <v-col cols="12" align="center">
                  <v-btn type="submit" color="primary" block>Login</v-btn>
                </v-col>
                <v-col cols="12" align="center">
                  <v-btn type="button" text color="primary"
                    >Esqueci minha senha</v-btn
                  >
                </v-col>
              </v-row>
            </v-card-actions>
          </v-form>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  data() {
    return {
      user: {
        email: "",
        password: ""
      },
      showPassword: false,
      error: false
    };
  }
};
</script>
